import { json, LoaderFunctionArgs } from '@remix-run/node'
import { Outlet, useLoaderData } from '@remix-run/react'
import { isbot } from 'isbot'
import { authenticator, isAuthenticated } from '~/services/auth.server'
import { graphqlRequest } from '~/utils/graphqlRequest'
import { MeDocument, MeQuery } from '~/graphql/generated/graphql'

export async function loader({ request }: LoaderFunctionArgs) {
  if (isbot(request.headers.get('user-agent') || '')) {
    return json({ me: { code: 'Anonymous', completeOnboarding: true } })
  }
  const session = await isAuthenticated(request)
  try {
    const { me } = await graphqlRequest<MeQuery>(MeDocument.toString(), session)
    return json({ me })
  } catch (_error) {
    const url = new URL(request.url)
    const redirectTo = encodeURIComponent(url.pathname + url.search)
    return await authenticator.logout(request, { redirectTo: `/login?redirectTo=${redirectTo}` })
  }
}

export const shouldRevalidate = () => false

export default function Me() {
  const { me } = useLoaderData<typeof loader>()
  return <Outlet context={me} />
}
